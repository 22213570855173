#input-field-encompass {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* border: 10px solid #000 */
}

#buttons-container {
    /* display: flex;
    flex-direction: row;
    align-items: center; */
    margin: 10px; /* Adjust this value as needed */
}

#buttons-container input {
    margin: 10px;
    transform: scale(1.5)
}

#buttons-container button {
    height: 30px;
    margin: 10px
}

#calculate-button-calculating {
    background-color: red
}

#calculate-button-done {
    background-color: lightgreen;
}

a {
    height: 30px;
    padding: 10px;
}


#anchors-container {
    display: flex;
    justify-content: center; /* Center the anchors horizontally */
    margin-bottom: 20px; /* Add some space between buttons and anchors */
  }
  
  #anchors-container a {
    margin-right: 10px; /* Adjust this value as needed */
  }