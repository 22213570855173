html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

/* #place-search {
  height: 100px;
} */

#place-search AddressDisplay {
  
}



#place-search input {
  margin: 10px;
  height: 100px;
  width: 100%
}

#place-search button {
  margin: 10px;
  height: 100px;


}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
