#display {
    display: flex;
    flex-direction: row;
    font-size: 0.8em;
}

#display-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 10px;
}

#display-container div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

#display button {
    background-color: pink;
    font-size: 1em;
    height: 50px;
    width: 200px;
    margin: 5px 0;
    padding: 10px;
    box-sizing: border-box; /* Ensure padding and border are included in width and height */
}

#display p {
    margin: 0;
    padding: 0;
}
