#all {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the #combined div horizontally within #all */
}

#combined {
    display: flex;
    flex-direction: column; /* Arrange child elements in a row */
    align-items: column; /* Center elements vertically */
    justify-content: space-between;
    border: 2px solid #000;
    width: 80%; /* Adjust width as needed */
    margin: 10px;
    padding: 10px;
}

#combined > div {
    flex: 1;
    margin: 10px; /* Add some margin to the child divs */
}

#combined button,
#combined input {
    width: 100%;
    margin: 10px 0; /* Add margin to space out input and buttons */
}

#combined input {
    height: 30px;
    font-size: 14px; /* Adjust font size to make text smaller */
    padding: 5px; /* Adjust padding to make input smaller */
}

#autocomplete-search {
    align-items: center;
}

#autocomplete-search input {
    width: 100%;
    font-size: 14px; /* Adjust font size to make text smaller */
    padding: 5px; /* Adjust padding to make input smaller */
}

#find-establishment-container,
#autocomplete-search {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center elements within each container */
}

#find-establishment-button {
    background: lightsalmon;
    width: 100%;
    height: 40px;
}

#confirm-button {
    background: lightgreen;
}

#add-address-button {
    background: lightblue;
    width: 100%;
    height: 40px;
}

#address-display {
    font-size: 0.8em;
}

#address-display button {
    background-color: pink;
    font-size: 1em;
    height: 30px;
    padding-bottom: 0;
    margin-top: 2px;
}

#address-display p {
    margin: 0;
    padding: 0;
}
